import { useState } from 'react'

import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import Button from '@/components/Button'
import Decoration2 from '@/components/SVGs/decoration2'
import { WORKS_EXP } from '@/utils/constants/data'
import { OPENSANS_BOLD, ROBOTO_BOLD } from '@/utils/constants/themes'

import { type WorksSectionProps } from '../types'

const WorksSection = ({ data }: WorksSectionProps) => {
  const { t } = useTranslation('home')

  const [selectedData, setSelectedData] = useState(data[data.length - 1])

  return (
    <div className="page-padding relative flex w-full flex-col items-center gap-y-16 py-16 lg:gap-x-8 lg:gap-y-24 lg:py-32">
      <div className="w-full" data-aos="fade-right">
        <h2 className={cn('h2 dark:text-primary', ROBOTO_BOLD.className)}>
          {t('home:work_title')}
        </h2>
        <p
          className={cn(
            'mt-3 text-3xl leading-normal text-secondary/50 dark:text-light/50',
          )}
        >
          {t('home:work_subtitle')}
        </p>
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-y-12 lg:flex-row lg:items-start lg:gap-x-8 xl:gap-x-32">
        <div
          className="relative flex w-full max-w-max lg:w-1/2 xl:justify-end"
          data-aos="flip-up"
          data-aos-delay="300"
        >
          <Decoration2 className="absolute left-0 top-0 -translate-x-12 -translate-y-20 opacity-50" />
          <div
            className={cn(
              'shadow-primary-md group relative h-max w-full max-w-xl overflow-hidden rounded-3xl bg-white p-8',
              'dark:shadow-primary-lg-dark',
            )}
          >
            <div className="my-transition absolute left-0 top-0 z-10 flex h-full w-full scale-0 items-center justify-center bg-primary/25 group-hover:scale-100">
              <Button
                asLink
                linkProps={{
                  href: selectedData.detailUrl,
                  target: '_blank',
                  rel: 'nofollow',
                  className:
                    'my-transition scale-0 group-hover:scale-100 group-hover:delay-300',
                }}
              >
                {t('common:view_detail')}
              </Button>
            </div>
            <Image
              width={480}
              height={320}
              src={selectedData.imageSrc ?? '/images/image.webp'}
              alt={selectedData?.title}
            />
          </div>
        </div>
        <div
          className="relative max-w-md lg:w-1/2"
          data-aos="flip-up"
          data-aos-delay="300"
        >
          <Decoration2 className="absolute right-0 top-1/2 z-[2] -translate-y-1/2 -rotate-45 opacity-50" />
          <ul className="w-full">
            {[...WORKS_EXP]
              .reverse()
              .map(({ id, desc, period, title, ...res }) => {
                return (
                  <li key={id} aria-label={title}>
                    <button
                      aria-label={title}
                      onClick={() => {
                        setSelectedData({ id, desc, period, title, ...res })
                      }}
                      className={cn(
                        'my-transition flex max-w-md flex-col gap-y-2 rounded-r-2xl border-l-8 bg-white px-8 py-6',
                        'dark:bg-dark',
                        {
                          'shadow-primary-md dark:shadow-primary-md-dark relative z-[3] cursor-default border-primary':
                            selectedData.id === id,
                          'border-secondary hover:bg-light':
                            selectedData.id !== id,
                        },
                      )}
                    >
                      <div className="flex items-center gap-x-4">
                        <span
                          className={cn(
                            'my-transition h3 flex h-12 w-12 items-center justify-center rounded-full bg-secondary px-4 text-white',
                            OPENSANS_BOLD.className,
                            { '!bg-primary': selectedData.id === id },
                          )}
                        >
                          {id}
                        </span>
                        <span className="h-max text-left">{period}</span>
                      </div>
                      <h3 className={cn('text-left', OPENSANS_BOLD.className)}>
                        {title}
                      </h3>
                      <p className="text-left">{t(`home:${desc}`)}</p>
                    </button>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default WorksSection
