import { type SVGProps } from 'react'

const Decoration2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="117"
      height="117"
      viewBox="0 0 117 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="2"
        y="2"
        width="67"
        height="67"
        stroke="rgb(var(--primary-color))"
        strokeWidth="4"
      />
      <rect
        x="48"
        y="48"
        width="67"
        height="67"
        stroke="rgb(var(--primary-color))"
        strokeWidth="4"
      />
    </svg>
  )
}

export default Decoration2
